import { computed, unref } from 'vue'
import { useCurrentCompanyQuery } from '@/api/useCompany'

/**
 * @deprecated use useAbility instead
 */
export const useAccess = () => {
  const { data: company } = useCurrentCompanyQuery()

  return {
    /**
     * @deprecated use $can from CASL instead
     */
    hasWriteAccess: computed(() => !!unref(company)?.hasCurrentUserWriteAccess),
  }
}
